/**
 * Name: Page
 */

import vhCheck from 'vh-check';
import Details from "./Details";
import Filters from "./Filters";
import Header from "./Header";
import SelectMultiple from "./SelectMultiple";
import Component from "@lunaweb/vega-reactor-js/src/patterns/Component";
import Expander from './Expander';
import Notice from './Notice';
import { setCookie, getCookie } from "../utilities/storage";

export default class Page extends Component {

  static settings = {};

  #tempWidth = 0;
  #hasResized = true;
  #hasScrolled = true;
  #hasOverlay = false;
  #loop;
  #headerElement;
  #header;
  #noticeElements;
  #notices = [];
  #detailsElements;
  #details = [];
  #filtersElements;
  #filters = [];
  #selectmultipleElements;
  #selectmultiple = [];
  #expanderElements;
  #expanders = [];

  #handleResize;
  #handleScroll;
  #handleNavigationUpdate;
  #handleNoticeClose;

  constructor (element, options = {}) {
    super(element, {
      ...Page.settings,
      ...options
    });

    this.#headerElement = document.getElementById('header');
    this.#noticeElements = this.element.querySelectorAll('.js-notice');
    this.#detailsElements = this.element.querySelectorAll('.js-details');
    this.#filtersElements = this.element.querySelectorAll('.js-filters');
    this.#selectmultipleElements = this.element.querySelectorAll('.js-selectmultiple');
    this.#expanderElements = this.element.querySelectorAll('.js-expander');

    this.#handleResize = this._handleResize.bind(this);
    this.#handleScroll = this._handleScroll.bind(this);
    this.#handleNavigationUpdate = this._handleNavigationUpdate.bind(this);
    this.#handleNoticeClose = this._handleNoticeClose.bind(this);
  }

  get width () {
    return window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
  }

  _handleResize (e) {
    this.#hasResized = true;
  }

  _handleScroll (e) {
    this.#hasScrolled = true;
  }

  _handleNavigationUpdate (navigation) {
    if (navigation.currentItem && !this.#hasOverlay) {
      this.element.classList.add('has-overlay');
      this.#hasOverlay = true;
    } else if (!navigation.currentItem && this.#hasOverlay) {
      this.element.classList.remove('has-overlay');
      this.#hasOverlay = false;
    }
  }

  _handleNoticeClose (notice) {
    notice.off('close');
    setCookie(notice.id, 1, notice.duration);
  }

  updateResize () {
    if (this.#tempWidth !== this.width) {
      if (this.#header.burger.isDisplayed && !this.#header.burger.mounted) {
        this.#header.burger.mount();
        this.#header.navigation.mode = 'vertical';
      } else if (!this.#header.burger.isDisplayed && this.#header.burger.mounted) {
        this.#header.burger.unmount();
        this.#header.navigation.mode = 'horizontal';
      }

      this.#filters.forEach((filters) => {
        let canCollapse = true;
        if (filters.mode === "vertical") {
          canCollapse = filters.element.offsetWidth > (this.width / 2);
        } else {
          canCollapse = filters.element.offsetWidth < 960;
        }
        if (canCollapse && !filters.mounted) {
          filters.mount();
        } else if (!canCollapse && filters.mounted) {
          filters.unmount();
        }
      });

      this.#tempWidth = this.width;
    }
  }

  updateScroll () {
    const vh = vhCheck();
    document.documentElement.style.setProperty('--vh-offset', `${vh.offset}px`);
  }

  updateCustomProperties () {
    const navigationHeight = this.#header.navigation ? this.#header.navigation.element.offsetHeight : 0;
    const topHeight = this.#header && this.#header.top ? this.#header.top.offsetHeight : 0;
    const mainHeight = this.#header && this.#header.main ? this.#header.main.offsetHeight : 0;

    document.documentElement.style.setProperty('--navigation-height', `${navigationHeight}px`);
    document.documentElement.style.setProperty('--header-top-height', `${topHeight}px`);
    document.documentElement.style.setProperty('--header-main-height', `${mainHeight}px`);
    document.documentElement.style.setProperty('--header-height', `${topHeight + mainHeight}px`);
    document.documentElement.style.setProperty('--header-global-height', `${topHeight + mainHeight + navigationHeight}px`);
  }

  mount () {
    if (this.#headerElement) {
      this.#header = new Header(this.#headerElement);
      this.#header.on('navigation:update', this.#handleNavigationUpdate);
      this.#header.mount();
    }

    this.#noticeElements.forEach((noticeElement) => {
      const notice = new Notice(noticeElement);
      if(!getCookie(notice.id)) {
        notice.on('close', this.#handleNoticeClose);
        notice.mount();
        this.#notices.push(notice);
      }
    });

    this.#detailsElements.forEach((detailsElement) => {
      const options = detailsElement.dataset.detailsTranslations ? {
        i18n: JSON.parse(detailsElement.dataset.detailsTranslations)
      } : {};

      const details = new Details(detailsElement, options);
      details.mount();
      this.#details.push(details);
    });

    this.#filtersElements.forEach((filtersElement) => {
      const filters = new Filters(filtersElement);
      this.#filters.push(filters);
    });

    this.#selectmultipleElements.forEach((selectmultipleElement) => {
      const selectmultiple = new SelectMultiple(selectmultipleElement);
      selectmultiple.mount();
      this.#selectmultiple.push(selectmultiple);
    });

    this.#expanderElements.forEach((element) => {
      const expander = new Expander(element);
      expander.mount();
    });

    const loop = () => {
      let needUpdate = false;

      if (this.#hasResized) {
        this.updateResize();
        this.#hasResized = false;
        needUpdate = true;
      }

      if (this.#hasScrolled) {
        this.updateScroll();
        this.#hasScrolled = false;
        needUpdate = true;
      }

      if (needUpdate) {
        this.updateCustomProperties();
        needUpdate = null;
      }

      requestAnimationFrame(loop);
    }

    this.#loop = requestAnimationFrame(loop);

    window.addEventListener("resize", this.#handleResize);
    document.addEventListener("scroll", this.#handleScroll);

    super.mount();
  }

  unmount () {
    document.removeEventListener("scroll", this.#handleScroll);
    window.removeEventListener("resize", this.#handleResize);

    cancelAnimationFrame(this.#loop);

    this.#expanders.forEach((expander) => {
      expander.unmount();
    });
    this.#expanders = [];

    this.#selectmultiple.forEach((selectmultiple) => {
      selectmultiple.unmount();
    });
    this.#selectmultiple = [];

    this.#filters.forEach((filters) => {
      filters.unmount();
    });
    this.#filters = [];

    this.#details.forEach((details) => {
      details.unmount();
    });
    this.#details = [];

    this.#notices.forEach((notice) => {
      notice.unmount();
    });
    this.#notices = [];

    if (this.#header) {
      this.#header.unmount();
    }
    super.unmount();
  }
}
