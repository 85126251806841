/**
 * Name: Expander
 */

import Component from "@lunaweb/vega-reactor-js/src/patterns/Component";

export default class Expander extends Component {

  static settings = {};

  #limit = 10;
  #itemElements;
  #control;

  constructor (element, options = {}) {
    super(element, {
      ...Expander.settings,
      ...options
    });

    this.#limit = this.element.dataset.expanderLimit ?  parseInt(this.element.dataset.expanderLimit, 10) : this.#limit;
    this.#itemElements = this.element.querySelectorAll('.js-expander-item');
    this.#control = this.element.querySelector('.js-expander-control');

    this.handleControlClick = this.handleControlClick.bind(this);
  }

  handleControlClick (e) {
    this.expand();
  }

  expand () {
    this.#itemElements.forEach((element, index) => {
      element.removeAttribute('hidden');
    });
    this.firstHiddenItem.focus();
    delete this.firstHiddenItem;
    this.#control.setAttribute('hidden', '');
    this.#control.removeEventListener('click', this.handleControlClick);
  }

  collapse () {
    this.#itemElements.forEach((element, index) => {
      element.setAttribute('tabindex', '-1');
      if (index >= this.#limit) {
        if (index === this.#limit) {
          this.firstHiddenItem = element;
        }
        element.setAttribute('hidden', '');
      }
    });
    this.#control.addEventListener('click', this.handleControlClick);
    this.#control.removeAttribute('hidden');
  }

  mount () {
    if (this.#itemElements.length > this.#limit) {
      this.collapse();
    }
  }

  unmount () {
    this.#control.removeEventListener('click', this.handleControlClick);
    this.#itemElements.forEach((item) => {
      item.removeAttribute('hidden');
      item.removeAttribute('tabindex');
    })
    this.#control.setAttribute('hidden', '');
  }
}
