import browserUpdate from 'browser-update/update.npm';
import Page from './front/components/Page';

document.addEventListener('DOMContentLoaded', () => {
  const pageElement = document.body;
  if (pageElement) {
    const page = new Page(pageElement);
    page.mount();
  }

  // See : https://github.com/browser-update/browser-update/wiki/Details-on-configuration
  browserUpdate({
    required: {
      e: -2, // Edge
      f: -2, // Firefox
      i: 12, // IE
      o: -2, // Opera
      s: -2, // Safari
      c: -2 // Chrome
    },
    insecure: true,
    api: 2024.01
  });
});
