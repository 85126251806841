/**
 * Name: Navigation
 * Description: Main navigation
 */

import Component from "@lunaweb/vega-reactor-js/src/patterns/Component";
import NavigationItem from "./NavigationItem";

export default class Navigation extends Component {
  static settings = {};

  #itemElements;
  #items;

  #handleNavigationItemClose;
  #handleNavigationItemOpen;

  constructor (element, options = {}) {
    super(element, {
      ...Navigation.settings,
      ...options
    });

    this.#itemElements = this.element.querySelectorAll('.js-navigation-item');
    this.#items = [];

    this.#handleNavigationItemClose = this._handleNavigationItemClose.bind(this);
    this.#handleNavigationItemOpen = this._handleNavigationItemOpen.bind(this);
  }

  set mode (format) {
    if (format === 'horizontal') {
      this.#items.forEach((navigationItem) => {
        navigationItem.target.classList.add('is-horizontal');
      });
    } else {
      this.#items.forEach((navigationItem) => {
        navigationItem.target.classList.remove('is-horizontal');
      });
    }
  }

  get currentItem () {
    let expandedItem = null;
    this.#items.forEach((item) => {
      expandedItem = item.expanded ? item : expandedItem;
    });

    return expandedItem;
  }

  _handleNavigationItemOpen (navigationItem) {
    this.fire('update', this);
  }

  _handleNavigationItemClose (navigationItem) {
    this.fire('update', this);
  }

  mount () {
    this.#itemElements.forEach((itemElement) => {
      const navigationItem = new NavigationItem(itemElement);
      navigationItem.on('close', this.#handleNavigationItemClose);
      navigationItem.on('open', this.#handleNavigationItemOpen);
      navigationItem.mount();
      this.#items.push(navigationItem);
    });

    super.mount();
  }

  unmount () {
    this.#items.forEach((navigationItem) => {
      navigationItem.off('close', this.#handleNavigationItemClose);
      navigationItem.off('open', this.#handleNavigationItemOpen);
      navigationItem.unmount();
    });

    this.#items = [];

    super.unmount();
  }
}
