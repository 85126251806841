/**
 * Name: NavigationControl
 * Description: Main navigation item
 */

import Collapsible from "@lunaweb/vega-reactor-js/src/patterns/Collapsible";

export default class NavigationItem extends Collapsible {
  static settings = {
    control: '.js-navigation-control',
    target: '.js-navigation-megamenu',
    close: '.js-navigation-close',
  };

  #closeButton;
  #handleCloseClick;

  constructor (element, options = {}) {
    super(element, {
      ...NavigationItem.settings,
      ...options,
    });

    this.#closeButton = this.getNode(this.settings.close);
    this.#handleCloseClick = this._handleCloseClick.bind(this);
  }

  _handleCloseClick (e) {
    super.close();
  }

  mount () {
    super.mount();

    if (this.#closeButton) {
      this.#closeButton.addEventListener('click', this.#handleCloseClick);
    }
  }

  unmount () {
    if (this.#closeButton) {
      this.#closeButton.removeEventListener('click', this.#handleCloseClick);
    }

    super.unmount();
  }
}
