export function setCookie(name, value, duration = false) {
  let expires = '';

  if (duration) {
    const date = new Date();
    date.setTime(date.getTime() + duration);
    expires = `expires=${ date.toUTCString() };`;
  }

  document.cookie = `${ name }=${ value };${ expires }path=/`;
}

export function getCookie (name) {
  const start = `${ name }=`;
  const decodedCookie = decodeURIComponent(document.cookie);
  const array = decodedCookie.split(';');

  for(var i = 0; i < array.length; i++) {
    var cookie = array[i];
    while (cookie.charAt(0) == ' ') {
      cookie = cookie.substring(1);
    }
    if (cookie.indexOf(start) == 0) {
      return cookie.substring(start.length, cookie.length);
    }
  }
  return false;
}
