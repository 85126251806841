/**
 * Name: Burger
 */

import Collapsible from "@lunaweb/vega-reactor-js/src/patterns/Collapsible";

export default class Burger extends Collapsible {
  static settings = {};

  constructor (element, options = {}) {
    super(element, {
      ...Burger.settings,
      ...options,
    });
  }

  get isDisplayed () {
    return getComputedStyle(this.element).display !== 'none';
  }

  open () {
    super.open();

    this.target.focus();
  }

  mount () {
    super.mount();
    this.target.classList.add('has-burger');
    this.target.setAttribute('tabindex', '-1');
  }

  unmount () {
    super.unmount();
    this.target.classList.remove('has-burger');
    this.target.removeAttribute('tabindex');
  }
}
