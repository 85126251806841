/**
 * Name: Notice
 */

import Component from "@lunaweb/vega-reactor-js/src/patterns/Component";

export default class Notice extends Component {

  static settings = {};

  #close;
  #duration;
  #id;

  #handleCloseClick;

  constructor (element, options = {}) {
    super(element, {
      ...Notice.settings,
      ...options
    });

    this.#id = this.element.id;
    this.#close = this.element.querySelector('.js-notice-close');
    this.#duration = this.element.dataset.noticeDuration ? parseInt(this.element.dataset.noticeDuration, 10) * 1000 : null;

    this.#handleCloseClick = this._handleCloseClick.bind(this);
  }

  get id () {
    return this.#id;
  }

  get duration () {
    return this.#duration;
  }

  _handleCloseClick (e) {
    this.fire('close', this);
    this.unmount();
    this.#close = null;
    this.element.remove();
  }

  mount () {
    this.#close.addEventListener('click', this.#handleCloseClick);
    super.mount();
  }

  unmount () {
    this.#close.removeEventListener('click', this.#handleCloseClick);
    super.unmount();
  }
}
