/**
 * Name: Header
 */

import Component from "@lunaweb/vega-reactor-js/src/patterns/Component";
import Navigation from "./Navigation";
import Burger from "./Burger";

export default class Header extends Component {
  static settings = {};

  #top;
  #main;
  #burgerElement;
  #burger;
  #navigationElement;
  #navigation;

  #handleNavigationUpdate;

  constructor (element, options = {}) {
    super(element, {
      ...Header.settings,
      ...options
    });

    this.#top = document.getElementById('header-top');
    this.#main = document.getElementById('header-main');
    this.#burgerElement = document.getElementById('burger');
    this.#navigationElement = document.getElementById('navigation');

    this.#handleNavigationUpdate = this._handleNavigationUpdate.bind(this);
  }

  get top () {
    return this.#top;
  }

  get main () {
    return this.#main;
  }

  get burger () {
    return this.#burger;
  }

  get navigation () {
    return this.#navigation;
  }

  _handleNavigationUpdate () {
    this.fire('navigation:update', this.#navigation);
  }

  mount () {
    if (this.#navigationElement) {
      this.#navigation = new Navigation(this.#navigationElement);
      this.#navigation.on('update', this.#handleNavigationUpdate);
      this.#navigation.mount();
    }

    if (this.#burgerElement && this.#navigationElement) {
      this.#burger = new Burger(this.#burgerElement, {
        control: this.#burgerElement,
        target: this.#navigationElement,
      });

      if (this.#burger.isDisplayed) {
        this.#burger.mount();
        this.#navigation.mode = 'vertical';
      } else {
        this.#navigation.mode = 'horizontal';
      }
    }

    super.mount();
  }

  unmount () {
    super.unmount();

    if (this.#burger) {
      this.#burger.unmount();
    }

    if (this.#navigation) {
      this.#navigation.off('update', this.#handleNavigationUpdate);
      this.#navigation.unmount();
    }
  }
}
