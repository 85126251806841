/**
 * Name: SelectMultiple
 */

import Collapsible from "@lunaweb/vega-reactor-js/src/patterns/Collapsible";

export default class SelectMultiple extends Collapsible {

  static settings = {
    control: '.js-selectmultiple-control',
    target: '.js-selectmultiple-menu',
    label: '.js-selectmultiple-label',
    option: '.js-selectmultiple-option',
    i18n: {
      label_empty: 'Sélectionnez une ou plusieurs valeurs',
      label_count: '%selected% sur %length% %descriptor%',
      singular: 'sélectionné',
      plural: 'sélectionnés',
      hideSection: 'Masquer',
      showSection: 'Afficher',
    }
  };

  #label;
  #options;

  #handleTargetChange

  constructor (element, options = {}) {
    super(element, {
      ...SelectMultiple.settings,
      ...options
    });

    this.#label = this.element.querySelector(this.settings.label);
    this.#options = this.target.querySelectorAll(this.settings.option);

    this.#handleTargetChange = this._handleTargetChange.bind(this);
  }

  get selected () {
    return Array.from(this.#options).filter((element) => { return element.checked });
  }

  _handleTargetChange (e) {
    const selected = this.selected;
    let text = this.settings.i18n.label_count;

    if (selected.length === 0) {
      text = this.settings.i18n.label_empty;
    } else if (selected.length === 1) {
      text = text.replace('%descriptor%', this.settings.i18n.singular);
    } else {
      text = text.replace('%descriptor%', this.settings.i18n.plural);
    }

    text = text.replace('%selected%', selected.length);
    text = text.replace('%length%', this.#options.length);


    this.#label.innerHTML = text;
  }

  open () {
    super.open();
    this.target.focus();
  }

  mount () {
    this.target.addEventListener('change', this.#handleTargetChange);
    super.mount();
    this.#handleTargetChange();
    if (this.element.dataset.selectmultipleLabel) {
      this.target.setAttribute('aria-labelledby', this.element.dataset.selectmultipleLabel);
    }
  }

  unmount () {
    this.target.removeEventListener('change', this.#handleTargetChange);
    super.unmount();
  }
}
