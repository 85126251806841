/**
 * Name: Filters
 */

import Collapsible from "@lunaweb/vega-reactor-js/src/patterns/Collapsible";

export default class Filters extends Collapsible {
  static settings = {
    control: '.js-filters-control',
    controlLabel: '.js-filters-control-label',
    target: '.js-filters-body',
    i18n: {
      hideSection: 'Masquer les filtres',
      showSection: 'Afficher les filtres',
    }
  };

  #controlLabel;

  constructor (element, options = {}) {
    super(element, {
      ...Filters.settings,
      ...options,
    });

    this.#controlLabel = this.getNode(this.settings.controlLabel);
  }

  get mode () {
    return this.element.dataset.filtersMode ? this.element.dataset.filtersMode : "vertical";
  }

  open () {
    this.#controlLabel.innerHTML = this.settings.i18n.hideSection;
    super.open();
  }

  close () {
    this.#controlLabel.innerHTML = this.settings.i18n.showSection;
    super.close();
  }
}
